import { get, isEmpty } from 'lodash';

type Warning = {
  warningType: string;
};

type Context = {
  warnings?: Warning[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type Payload = {
  data?: {
    bauhausDataRequest?: {
      warnings?: Warning[];
    };
  };
};

export const warningsTransform = (
  payload: Payload,
  __: unknown,
  ___: unknown,
  context: Context,
): Context => {
  const warnings: Warning[] = get(
    payload,
    'data.bauhausDataRequest.warnings',
    [],
  );

  const rawDataWarnings: Warning[] = get(
    payload,
    'data.bauhausRawDataRequest.warnings',
    [],
  );

  const allWarnings = [...warnings, ...rawDataWarnings];
  const hasWarnings = !isEmpty(allWarnings);

  if (hasWarnings) {
    return {
      ...context,
      warnings: allWarnings,
    };
  }

  return context;
};
