import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';

const sharedConnectionSchemaProperties = {
  'Auth type': SchemaTypes.oneOf([
    'oauth',
    'oauth2',
    'basic',
    'null',
    'generic',
    'oauth1',
  ]).isRequired,
  'Integration name': SchemaTypes.string.isRequired,
  'Integration slug': SchemaTypes.string.isRequired,
  'Data source': SchemaTypes.string,
};

export const connectionCreated = defineEvent(
  'Connection - Created',
  sharedConnectionSchemaProperties,
);

export const connectionUpdated = defineEvent(
  'Connection - Updated',
  sharedConnectionSchemaProperties,
);

export const connectionDeleted = defineEvent(
  'Connection - Deleted',
  sharedConnectionSchemaProperties,
);

export const connectionFailed = defineEvent('Connection - Failed', {
  ...sharedConnectionSchemaProperties,
  'Connection failure reason': SchemaTypes.string,
});

export const connectionSelected = defineEvent(
  'Connection - Selected',
  sharedConnectionSchemaProperties,
);

export const externalConnectionInitiated = defineEvent(
  'External connection - Initiated',
);

export const externalConnectionCreated = defineEvent(
  'External connection - Created',
);

export const externalConnectionFailed = defineEvent(
  'External connection - Failed',
);

export const externalConnectionDeleted = defineEvent(
  'External connection - Deleted',
);
