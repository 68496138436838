import { chain, flatten, isArray, isFinite, isString, last, map } from 'lodash';

import {
  getSelectedCells,
  greedilyParseFloat,
} from '../../spreadsheet-helpers';
import { composeTransformers } from '../compose-transformers';
import { transform as comparisonTransform } from '../per-visualisation/number/comparison';
import { transform as numberFormatTransform } from '../shared/number-format';

import { getSelectionFormat } from './helpers';
import { transform as statusIndicatorsTransform } from './status-indicators';

const getNumberSeriesValues = (allCells, selection) => {
  const [cells] = getSelectedCells(allCells, [selection]) || [[]];
  const values = map(cells, 'value').map(greedilyParseFloat);

  return { cells, values };
};

const getNumberValue = (cells, value, format) => {
  let v = value;
  if (isArray(v)) {
    v = chain(cells)
      .thru((cells) => getSelectedCells(cells, [v])) // eslint-disable-line @typescript-eslint/no-shadow
      .head()
      .map('value')
      .last()
      .value();
  }

  if (isString(v)) {
    v = parseFloat(v);
  }

  // Convert a typed goal to a percentage
  if (format === 'percent' && !isArray(value)) {
    v /= 100;
  }

  return v;
};

const injectComparisonValues = (value, comparison, cells) => {
  const comparisonValues = getNumberSeriesValues(
    cells,
    comparison.value,
  ).values.filter(isFinite);

  return [...comparisonValues, last(value)];
};

const numberTransformV1 = (
  { cells },
  { value, goal, comparison = {}, progressIndicator },
  timezone,
  initialContext,
) => {
  const context = {};
  let { cells: selectedCells, values: parsedValues } = getNumberSeriesValues(
    cells,
    value,
  );

  // Omit the first value if we cant coerce it into a float
  if (isNaN(parsedValues[0])) {
    parsedValues = parsedValues.slice(1);
    selectedCells = selectedCells.slice(1);
  }

  // Ensure we only consider the format of the cell that we
  // are going to display not the whole column/row
  const [selectionFormat, unit] = getSelectionFormat(selectedCells.slice(-1));
  let format = selectionFormat;

  // The number widget can't handle dates
  if ('datetime' === format) {
    format = 'decimal';
  }

  if (flatten(parsedValues).length) {
    context.format = format;
    context.unit = unit;
    context.value = parsedValues;
  }

  const threshold = getNumberValue(cells, goal, format);
  if (isFinite(threshold)) {
    context.threshold = threshold;
  }

  const startingValue = getNumberValue(cells, comparison.startingValue, format);
  if (isFinite(startingValue)) {
    context.startingValue = startingValue;
  }

  // With the new number viz, we need to support the modern
  // config format here as well as the old goal above.
  if (progressIndicator) {
    const targetValue = getNumberValue(
      cells,
      progressIndicator.targetValue,
      format,
    );

    if (isFinite(targetValue)) {
      const progressStartingValue =
        getNumberValue(cells, progressIndicator.startingValue, format) || 0;

      context.progressIndicator = {
        startingValue: progressStartingValue,
        targetValue,
      };
    }
  }

  if (context.value && isArray(comparison.value)) {
    context.value = injectComparisonValues(
      context.value,
      comparison,
      cells,
      format,
    );
  }

  return { ...initialContext, ...context };
};

const numberTransform = (data, config, timezone, initialContext = {}) => {
  return numberTransformV1(data, config, timezone, initialContext);
};

const transform = composeTransformers(
  numberTransform,
  numberFormatTransform,
  comparisonTransform,
  statusIndicatorsTransform,
);

export { transform };
