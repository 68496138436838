import { get, last } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericBarTransform } from '../per-visualisation/bar';

import { durationTransform, transformBarOrColumnChart } from './shared';
import { warningsTransform } from './warning';

const singleSeriesTransform = (_, __, ___, initialContext) => {
  const { series } = initialContext;

  let singleSeries = get(series, '[0].data');

  if (!singleSeries) {
    return initialContext;
  }

  singleSeries = singleSeries.map((row) => last(row));

  return {
    ...initialContext,
    series: [{ data: singleSeries }],
  };
};

const transform = composeTransformers(
  transformBarOrColumnChart('horizontal'),
  genericBarTransform,
  durationTransform,
  singleSeriesTransform,
  warningsTransform,
);

const applyConfig = (data, config) => {
  return { ...data, numberFormat: config.numberFormat };
};

export { applyConfig, transform };
