import { chain, clamp, filter, sortBy } from 'lodash';

import { GRID_COLUMNS, GRID_ROWS } from './container-layout-constants';

const hasSingleFrame = (frames) => frames.length === 1;

const isContainerFull = (area, frames) => {
  // When container span is 1 in either direction or the main
  // span is the equal to or less than the number of frames,
  // show only one resize message instead of one on each frame.
  return (
    Math.min(area.columnSpan, area.rowSpan) <= 1 ||
    Math.max(area.columnSpan, area.rowSpan) <= frames.length
  );
};

const getCellWidth = (dashboardWidth, gridGap) => {
  return (dashboardWidth + gridGap) / GRID_COLUMNS;
};

const getCellHeight = (dashboardHeight, gridGap) => {
  return (dashboardHeight + gridGap) / GRID_ROWS;
};

// returns cell height and width for the current dashboard size - each cell includes one gap
const getCellSize = (dashboardWidth, dashboardHeight, gridGap) => {
  const cellWidth = getCellWidth(dashboardWidth, gridGap);
  const cellHeight = getCellHeight(dashboardHeight, gridGap);
  return [cellWidth, cellHeight];
};

const getContainerCellSize = (
  dashboardWidth,
  dashboardHeight,
  isColumnLayout,
  area,
  numberOfCells,
  gridGap,
) => {
  const [cellWidth, cellHeight] = getCellSize(
    dashboardWidth,
    dashboardHeight,
    gridGap,
  );

  if (isColumnLayout) {
    const containerWidth = cellWidth * area.columnSpan - gridGap;
    const width = containerWidth / numberOfCells;
    return width;
  }

  const containerHeight = cellHeight * area.rowSpan - gridGap;
  const height = containerHeight / numberOfCells;
  return height;
};

// Returns true when container a and b are intersecting in some way
const isIntersecting = (a, b) =>
  !(
    a.rowStart + a.rowSpan - 1 < b.rowStart ||
    a.rowStart + 1 > b.rowStart + b.rowSpan ||
    a.columnStart + a.columnSpan - 1 < b.columnStart ||
    a.columnStart + 1 > b.columnStart + b.columnSpan
  );

// Returns true when container a contains container b
const isContainedBy = (a, b) => {
  const rowEndA = a.rowStart + a.rowSpan;
  const columnEndA = a.columnStart + a.columnSpan;
  const rowEndB = b.rowStart + b.rowSpan;
  const columnEndB = b.columnStart + b.columnSpan;
  return (
    a.rowStart <= b.rowStart &&
    a.rowSpan >= b.rowSpan &&
    a.columnStart <= b.columnStart &&
    a.columnSpan >= b.columnSpan &&
    rowEndA >= rowEndB &&
    columnEndA >= columnEndB
  );
};

const getXAndYOverlap = (a, b) => {
  const aColumnEnd = a.columnStart + a.columnSpan;
  const bColumnEnd = b.columnStart + b.columnSpan;
  const aRowEnd = a.rowStart + a.rowSpan;
  const bRowEnd = b.rowStart + b.rowSpan;

  const xOverlap = Math.max(
    0,
    Math.min(aColumnEnd, bColumnEnd) - Math.max(a.columnStart, b.columnStart),
  );
  const yOverlap = Math.max(
    0,
    Math.min(aRowEnd, bRowEnd) - Math.max(a.rowStart, b.rowStart),
  );
  return { xOverlap, yOverlap };
};

const getOverlappedArea = (a, b) => {
  const { xOverlap, yOverlap } = getXAndYOverlap(a, b);
  return xOverlap * yOverlap;
};

const getTopSection = (space, area) => {
  const newRowSpan = Math.max(0, area.rowStart - space.rowStart);

  return {
    rowStart: space.rowStart,
    rowSpan: newRowSpan,
    columnStart: space.columnStart,
    columnSpan: space.columnSpan,
  };
};

const getRightSection = (space, area) => {
  const areaColumnEnd = area.columnStart + area.columnSpan;
  const newColumnSpan = Math.max(
    0,
    space.columnStart + space.columnSpan - areaColumnEnd,
  );

  return {
    rowStart: space.rowStart,
    rowSpan: space.rowSpan,
    columnStart: areaColumnEnd,
    columnSpan: newColumnSpan,
  };
};

const getBottomSection = (space, area) => {
  const areaRowEnd = area.rowStart + area.rowSpan;
  const newRowSpan = Math.max(0, space.rowStart + space.rowSpan - areaRowEnd);

  return {
    rowStart: areaRowEnd,
    rowSpan: newRowSpan,
    columnStart: space.columnStart,
    columnSpan: space.columnSpan,
  };
};

const getLeftSection = (space, area) => {
  const newColumnSpan = Math.max(0, area.columnStart - space.columnStart);

  return {
    rowStart: space.rowStart,
    rowSpan: space.rowSpan,
    columnStart: space.columnStart,
    columnSpan: newColumnSpan,
  };
};

// Note: `getSections` is tested in the place where it's being used
const getSections = (space, area) => {
  const topSection = getTopSection(space, area);
  const rightSection = getRightSection(space, area);
  const bottomSection = getBottomSection(space, area);
  const leftSection = getLeftSection(space, area);

  // Get new sections and remove the ones that have a
  // row or column span of 0
  const sections = [
    topSection,
    rightSection,
    bottomSection,
    leftSection,
  ].filter((section) => section.rowSpan !== 0 && section.columnSpan !== 0);

  return sections;
};

const getPointerArea = (
  offset,
  dashboardOffset,
  dashboardWidth,
  dashboardHeight,
  gridGap,
) => {
  const [cellWidth, cellHeight] = getCellSize(
    dashboardWidth,
    dashboardHeight,
    gridGap,
  );
  // always rounds down to find the start of the cell the pointer is over,
  // prevents getting a pointer area that collides with a container in the next cell
  const row = Math.floor((offset.y - dashboardOffset.top) / cellHeight) + 1;
  const column = Math.floor((offset.x - dashboardOffset.left) / cellWidth) + 1;

  return {
    rowStart: clamp(row, 1, GRID_COLUMNS),
    columnStart: clamp(column, 1, GRID_COLUMNS),
    rowSpan: 1,
    columnSpan: 1,
  };
};

const getSnappedArea = (
  originalArea,
  delta,
  dashboardWidth,
  dashboardHeight,
  gridGap,
) => {
  const [cellWidth, cellHeight] = getCellSize(
    dashboardWidth,
    dashboardHeight,
    gridGap,
  );
  const newRowStart = originalArea.rowStart + Math.round(delta.y / cellHeight);
  const newColumnStart =
    originalArea.columnStart + Math.round(delta.x / cellWidth);

  return {
    rowStart: newRowStart,
    columnStart: newColumnStart,
    rowSpan: originalArea.rowSpan,
    columnSpan: originalArea.columnSpan,
  };
};

const getBestSpace = (spaces, pointerArea, newArea) => {
  // First find spaces, that include the area of the pointer
  const suitableSpaces = spaces.filter((space) =>
    isContainedBy(space, pointerArea),
  );

  if (!suitableSpaces.length) {
    return null;
  }

  // Out of the suitableSpaces, find the one space that the new
  // area overlaps with the most.
  return chain(suitableSpaces)
    .map((space) => [getOverlappedArea(newArea, space), space])
    .filter(([overlappedAreaSize]) => !!overlappedAreaSize)
    .sort(([a], [b]) => a - b)
    .map(([, space]) => space)
    .last()
    .value();
};

const getFittedArea = (space, area) => {
  // Span is either the size of the item area, or if the best space is smaller
  // the span will be smaller.
  const rowSpan = Math.min(space.rowSpan, area.rowSpan);
  const columnSpan = Math.min(space.columnSpan, area.columnSpan);

  let rowStart = space.rowStart;
  let columnStart = space.columnStart;

  // If the space has more rows than the area,
  // ensure that the dropbox has a minimum height.
  if (space.rowSpan > area.rowSpan) {
    const spaceRowEnd = space.rowStart + space.rowSpan;
    rowStart = Math.max(area.rowStart, space.rowStart);

    if (rowStart + rowSpan > spaceRowEnd) {
      rowStart = spaceRowEnd - rowSpan;
    }
  }

  // If the space has more columns than the area,
  // ensure that the dropbox has a minimum width.
  if (space.columnSpan > area.columnSpan) {
    const spaceColumnEnd = space.columnStart + space.columnSpan;
    columnStart = Math.max(area.columnStart, space.columnStart);

    if (columnStart + columnSpan > spaceColumnEnd) {
      columnStart = spaceColumnEnd - columnSpan;
    }
  }

  return {
    rowStart,
    columnStart,
    rowSpan,
    columnSpan,
  };
};

// Converts the position of a frame in a container to it's position that
// it would have on the dashboard. This enables us to display a dropzone
// when moving a frame to the dashboard.
const getFrameAreaOnDashboard = (
  frameIndex,
  frames,
  containerArea,
  containerCells,
  isColumnLayout,
) => {
  const getSpanOnDashboard = (containerSpan, frameSpan) => {
    const span = (containerSpan / containerCells) * frameSpan;

    // Special-case for when we have a bunch of widgets in a group
    // By letting 1.2-span widgets behave like 2-span widgets
    // we allow rendering at slightly smaller sizes
    if (span < 2 && span >= 1.2) return 2;

    return Math.round(span);
  };

  const frame = frames[frameIndex];
  const prevFrameSpans = frames
    .slice(0, frameIndex)
    .reduce((acc, f) => acc + f.span, 0);

  if (isColumnLayout) {
    const containerSpan = containerArea.columnSpan;
    const columnSpan = getSpanOnDashboard(containerSpan, frame.span);
    const cellsTop = getSpanOnDashboard(containerSpan, prevFrameSpans);
    return {
      ...containerArea,
      columnStart: containerArea.columnStart + cellsTop,
      columnSpan,
    };
  }

  const containerSpan = containerArea.rowSpan;
  const frameRowSpan = getSpanOnDashboard(containerSpan, frame.span);
  const cellsTop = getSpanOnDashboard(containerSpan, prevFrameSpans);
  return {
    ...containerArea,
    rowStart: containerArea.rowStart + cellsTop,
    rowSpan: frameRowSpan,
  };
};

const SMALLEST_SPANS = {
  Bar: { column: 2, row: 3 },
  Bullet: { column: 4, row: 3 },
  Clock: { column: 2, row: 4 },
  Column: { column: 2, row: 3 },
  Feed: { column: 3, row: 3 },
  Funnel: { column: 2, row: 3 },
  Geckometer: { column: 2, row: 3 },
  Highcharts: { column: 2, row: 2 },
  Image: { column: 2, row: 2 },
  Label: { column: 2, row: 2 },
  Leaderboard: { column: 2, row: 2 },
  LegacyLine: { column: 2, row: 3 },
  Line: { column: 2, row: 3 },
  List: { column: 2, row: 3 },
  Map: { column: 2, row: 3 },
  Monitoring: { column: 2, row: 3 },
  Number: { column: 2, row: 2 },
  PieChart: { column: 2, row: 3 },
  Rag: { column: 2, row: 3 },
  Table: { column: 2, row: 3 },
  Text: { column: 2, row: 2 },
};
const isFrameTooSmall = (areaOnDashboard, visualisationType) => {
  const smallestSpans = SMALLEST_SPANS[visualisationType];

  if (!smallestSpans) {
    // For visualisations that don't have a defined smallest size,
    // use 1 span either direction as their smallest size.
    return Math.min(areaOnDashboard.columnSpan, areaOnDashboard.rowSpan) < 2;
  }

  return (
    areaOnDashboard.columnSpan < smallestSpans.column ||
    areaOnDashboard.rowSpan < smallestSpans.row
  );
};

const findLeftMostArea = (emptySpaces) => {
  // sort empty spaces to get lowest rowStart
  // which will be rowStart of the topmost space or spaces
  // and use these to filter out only spaces with this
  // rowStart
  const [{ rowStart }] = sortBy(emptySpaces, ['rowStart']);
  const topmostAreas = filter(emptySpaces, { rowStart });

  // sort the remaining topmost spaces by lowest columnStart
  // the first in the array will be the leftmost
  const [leftmostArea] = sortBy(topmostAreas, ['columnStart']);
  return leftmostArea;
};

// In certain circumstances (ie. adding a new widget or duplicating one), we need to use the
// `emptySpaces` from Redux state to calculate what the best spot for a new widget would be. Ideally,
// it's a 3x4 space.
export const findBestAreaForNewWidget = (emptySpaces) => {
  let currentBiggestArea = 0;
  let currentBiggestSpaces = [];

  emptySpaces.forEach((emptySpace) => {
    const { rowStart, columnStart } = emptySpace;
    // this would be the ideal space (3x4)
    const newContainerArea = {
      rowStart,
      rowSpan: 4,
      columnStart,
      columnSpan: 3,
    };

    const { xOverlap, yOverlap } = getXAndYOverlap(
      emptySpace,
      newContainerArea,
    );

    const overlappedArea = xOverlap * yOverlap;

    // if there are multiple empty spaces with the biggest area,
    // we need to save all of these to choose the top-left-most later
    if (overlappedArea === currentBiggestArea) {
      currentBiggestSpaces.push({
        rowStart,
        rowSpan: yOverlap,
        columnStart,
        columnSpan: xOverlap,
      });
    }

    // if the overlapped area is bigger than the previous biggest area, overwrite it
    if (overlappedArea > currentBiggestArea) {
      currentBiggestArea = overlappedArea;
      currentBiggestSpaces = [
        {
          rowStart,
          rowSpan: yOverlap,
          columnStart,
          columnSpan: xOverlap,
        },
      ];
    }
  });

  const bestSpace =
    currentBiggestSpaces.length > 1
      ? findLeftMostArea(currentBiggestSpaces)
      : currentBiggestSpaces[0];
  return bestSpace;
};

export {
  getBestSpace,
  getCellSize,
  getCellWidth,
  getContainerCellSize,
  getFittedArea,
  getFrameAreaOnDashboard,
  getOverlappedArea,
  getPointerArea,
  getSections,
  getSnappedArea,
  getXAndYOverlap,
  hasSingleFrame,
  isContainedBy,
  isContainerFull,
  isFrameTooSmall,
  isIntersecting,
};
