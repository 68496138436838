import d3 from 'd3';

import { getCSSColorVariable } from '@Lib/colors';

// For simplicity, the colours other than the primary series are fixed
const MULTISERIES_COLOURS = {
  '--series2-color': d3.hsl(48, 0.74, 0.61),
  '--series3-color': d3.hsl(287, 0.74, 0.61),
  '--series4-color': d3.hsl(173, 0.74, 0.61),
  '--series5-color': d3.hsl(14, 0.74, 0.61),
  '--series6-color': d3.hsl(79, 0.74, 0.61),
  '--series7-color': d3.hsl(336, 0.74, 0.61),
  '--series8-color': d3.hsl(130, 0.74, 0.61),
  '--series9-color': d3.hsl(266, 0.74, 0.61),
};

export default function ColourBrewer() {
  this.init();
}

ColourBrewer.prototype = {
  init: function init() {
    const primaryVisualisationColor = getCSSColorVariable(
      '--primary-visualisation-color',
      '#00D1FF',
    );

    // Get all other series colors from CSS, or use default colors
    const multiSeriesColors = Object.keys(MULTISERIES_COLOURS).map(
      (variable) => {
        const defaultColor = MULTISERIES_COLOURS[variable];
        return d3.rgb(getCSSColorVariable(variable, defaultColor.toString()));
      },
    );

    this.colour = d3.rgb(primaryVisualisationColor);
    this.colours = [this.colour, ...multiSeriesColors];
    this.next = 0;
  },

  /**
   * TODO: Replace hardcoded colours with a function.
   * this function will take this.colour as a base to compute the next
   * colour.
   **/
  getNextColour: function getNextColour() {
    const colour = this.colours[this.next];
    this.next++;
    // For now will repeat colours after reaching the maximun of the brewery
    if (this.next >= this.colours.length) {
      this.next = 0;
    }
    return colour;
  },
};
